.loading-state {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(179, 179, 179, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #ddd;
    border-top-color: #600;
    animation: loading 1s linear infinite;
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}