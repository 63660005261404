 @font-face {
     font-family: iransans;
     src: url(./../fonts/iransans/woff/IRANSansX-Light.woff);
 }
 html, body {
    height: 100%;
  }
 * {
     margin: 0;
     padding: 0;
     direction: rtl;
     font-family: iransans;
 }

 #root {
     margin: 0;
     padding: 0;
     display: flex;
     flex-direction: column;
     height: 100%;
 }

 .nextPrevItemArrow .nextBtn {
     position: fixed;
     top: 45%;
     margin: 0 auto;
     right: 2%;
 }

 .nextPrevItemArrow .prevBtn {
     position: fixed;
     top: 45%;
     margin: 0 auto;
     left: 2%;
 }

 .nextPrevItemArrow img {
     display: block;
     width: 30px;
     height: 50px;
     box-shadow: inset 0 0 15px 5px #BB010B;
     width: 20px;
     height: 20px;
     padding: 15px 0;
 }

 footer {
     height: 65px;
     width: 100%;
     border-top: 1px solid #A6A6A6;
     background-color: #F9F9F9;
     position: fixed;
     bottom: 0;
     right: 0;
     left: 0;
 }

 header {
     height: 65px;
     background-color: #F9F9F9;
     border-bottom: 1px solid #A6A6A6;
     width: 100%;
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     z-index: 999;
 }

 .position-relative {
     position: relative;
 }

 .justify-content-evenly {
     justify-content: space-evenly;
 }

 .justify-content-center {
     justify-content: center;
 }

 .pl-1 {
     padding-left: 8px;
 }

 .f-left {
     float: left;
 }

 .h-100 {
     height: 100%;
 }

 .w-100 {
     width: 100%;
 }

 .d-flex {
     display: flex;
 }

 .justify-content-between {
     justify-content: space-between;
 }

 .align-items-center {
     align-items: center;
 }

 .w-50 {
     width: 50%;
 }

 .text-left {
     text-align: left;
 }

 .pageNumber {
     direction: ltr;
 }

 .inputTextRout {
     transition: 0.5s;
     width: 100px;
     position: absolute;
     right: 20%;
     top: 1%;
 }

 .popUp {
     width: 100%;
     height: 100%;
     position: fixed;
     right: 0;
     background: #ff00003d;
     top: 0;
 }

 .contentAbout {
     position: relative;
     width: 100vw;
     height: 80vh;
     background-image: url('./../images/specialBack.jpg');
     animation: fade-in-page 0.5s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
     opacity: 0;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .contentAll {
     position: relative;
     background-image: url('./../images/specialBack2.jpg');
     animation: fade-in-page 0.5s;
     animation-fill-mode: forwards;
     opacity: 0;
     animation-direction: alternate !important;
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
     flex: 1;
     height: fit-content;
     margin-top: 65px;
 }

 @keyframes fade-in-page {
     from {
         opacity: 0;
     }

     to {
         opacity: 1;
     }
 }

 .contentAll img {
     animation: fade-in-page-img 2s;
     animation-fill-mode: forwards;
     opacity: 0;
     animation-direction: alternate !important;
 }

 .detailesImage {
     width: 100px;
 }

 .productImage {
     width: 110px;
 }

 @keyframes fade-in-page-img {
     from {
         opacity: 0;
     }

     to {
         opacity: 1;
     }
 }

 .media {
     width: 80%;
     position: absolute;
     bottom: 65px;
     display: flex;
     justify-content: space-evenly;
     animation: mediaAnimation 1s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
     flex-direction: column;
     left: 0;
     right: 0;
     margin: 0 auto;
 }

 .media a img {
     width: 40px;
 }

 @keyframes mediaAnimation {
     0% {
         bottom: -100px;
     }

     60% {
         bottom: 90px;
     }


     100% {
         bottom: 80px;
     }
 }


 .nameCompony {
     display: flex;
     justify-content: center;
     align-items: center;
     border-bottom: 3px solid #BB010B;
     padding-bottom: 10px;
     margin-bottom: 10px;
 }

 .nameCompony img {
     width: 40px;
 }

 .nameCompony p {
     font-size: 16px;
     font-weight: bold;
     color: #BB010B;
 }

 .aboutUSCompony img {
     width: 20px;
     margin-left: 10px;
 }

 .flex-direction-col {
     flex-direction: column;
 }

 .w-80 {
     width: 80%;
 }

 .f-10 {
     font-size: 10px !important;
 }

 .text-rotate-45 {
     transform: rotate(-45deg);
     position: absolute;
     top: 0;
     right: 0;
     left: 0;
     bottom: 0;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 12px;
     font-weight: bold;
     color: #414141;
     text-decoration: none;
 }

 .rotate-45 {
     width: 48px;
     aspect-ratio: 1;
     display: block;
     background-color: #F9F9F9;
     border: 2px solid #DF010D;
     border-radius: 8px;
     transform: rotate(45deg);
 }
.gap-1{
    gap: 1rem;
}
 .nameComponyAbout {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-bottom: 10px;
     width: 65%;
     margin: 0 auto;
     
 }

 .contentChildAbout {
     padding: 50px 0;
     height: 85%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-evenly;
     background-image: url('./../images/framework.png');
     background-repeat: no-repeat;
     background-position: center;
 }

 .w-60 {
     width: 60% !important;
 }

 .nameComponyAbout .d-flex.align-items-center {
     border-bottom: 3px solid #BB010B;
 }

 .nameComponyAbout p {
     color: #BB010B;
     font-weight: bold;
 }

 .titleCategury {
     font-size: 16px;
     color: #BB010B;
     font-weight: bold;
 }

 .categuryText {
     width: 100%;
     position: absolute;
     right: -100%;
     top: 0;
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     /* align-items: flex-end; */
     animation: showRight 0.5s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
 }

 @keyframes showRight {
     from {
         right: -100%;
     }

     to {
         right: 0%;
     }
 }

 @keyframes showLeft {
     from {
         left: -60%;
     }

     to {
         left: 0%;
     }
 }

 /* 
    .categuryTextLeft {
        width: 50%;
        position: absolute;
        left: -60%;
        top: 10vh;
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        animation: showLeft 0.5s;
        animation-fill-mode: forwards;
        animation-direction: alternate !important;
    } */


 @keyframes upTo {
     from {
         bottom: -100%;

     }

     to {
         bottom: 0%;
     }
 }

 .shareMedia {
     width: 90%;
     margin: 0 auto;
     height: 75%;
     background-color: #F9F9F9;
     position: fixed;
     bottom: -100%;
     right: 0;
     left: 0;
     border-radius: 16px 16px 0 0;
     animation: upTo 0.5s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
     z-index: 999;
 }

 @keyframes fade {
     from {
         opacity: 0;
     }

     to {
         opacity: 1;

     }
 }

 .popUpMedia {
     width: 100%;
     height: 100Vh;
     display: block;
     background-color: rgba(8, 8, 8, 0.459);
     position: fixed;
     top: 0;
     bottom: 0;
     right: 0;
     left: 0;
     animation: fade 0.5s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
     backdrop-filter: blur(5px);
 }

 .aspect {
     aspect-ratio: 1;
 }

 .d-none {
     display: none;
 }

 .imagesCategury {
     width: 60px;
     aspect-ratio: 1;
     border-radius: 50px;
 }

 .unitProductCatefury {
     width: fit-content;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;

 }

 .unitTitleCategury {
     display: flex;
     align-items: center;
     justify-content: center;
     padding-left: 8px;
 }

 .titleAbout {
     font-size: 11px;
     font-weight: bold;
     width: 80%;
     margin: 0 auto;
     text-align: center;
     padding-top: 16px;
 }

 .descriptionAbout {
     font-size: 10px;
     line-height: 20px;
     padding-top: 16px;
 }

 .itemCategury {
     background-color: #F9F9F9;
     box-shadow: 4px 3px 7px 0px #00000078;
     width: 100px;
     aspect-ratio: 1;
     border: 4px solid #BB010B;
     display: block;
     border-radius: 8px;
     transform: rotate(45deg);
 }

 .itemCategury a {
     transform: rotate(-45deg);
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width: 100%;
     height: 100%;
     text-decoration: none;
 }

 .itemCategury p {
     font-size: 10px;
     width: 75%;
     text-align: center;
     color: #414141;
     font-weight: bold;
 }

 .unitTitleProduct {
     width: 100%;
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
     flex-direction: column;
     height: 100%;
     /* padding-top: 65px; */
 }

 .contentTitleProduct {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 5px 0;
     width: 90%;
     margin: 0 auto;
 }

 .itemProduct {
     display: flex;
     /* width: 100px; */
     aspect-ratio: 1;
     border: 3px solid #BB010B;
     border-radius: 16px;
     flex-basis: 45%;
     margin-bottom: 15px;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     text-decoration: none;
 }

 .itemProduct span {
     color: #000;
     font-size: 10px;
     letter-spacing: -1px;
     text-align: center;
 }

 .groupingProduct {
     width: 90%;
     margin: 0 auto;
     display: flex;
     justify-content: space-around;
     align-items: center;
     flex-grow: 1;
     flex-wrap: wrap;
     margin-top: 10px;
     height: 100%;
     overflow: auto;
 }

 .nameCopmpony span {
     color: #BB010B;
     font-size: 12px;
     font-weight: bold;
 }

 .nameCopmpony {
     display: flex;
     align-items: center;
     justify-content: right;
     width: 90%;
     margin: 0 auto;
 }



 .titleCallUs {
     color: #33363F;
     text-align: center;
     font-size: 12px;
     font-weight: bold;
     padding: 10px 0;
 }

 .boxAddress {
    width: 85%;
    margin: 0 auto;
    border-top: 1px solid #E5E2E2;
    padding: 25px 14px;
    color: #414141;
 }

 .font-bold {
     font-weight: bold;
 }

 .linkBtn {
     text-decoration: none;
     color: #BB010B;
     border: 1px solid #BB010B;
     background-color: transparent;
     height: 34px;
     border-radius: 8px;
     margin-top: 8px;
     font-weight: bold;
     font-size: 12px;
     line-height: 34px;
     text-align: center;
 }

 .mapBox {
     width: 95%;
     margin: 0 auto;
     margin-top: 16px;
     border-radius: 16px;
     overflow: clip;
     padding: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .box-link {
     width: 95%;
     margin: 0 auto;
 }

 .mediaPopUp {
     width: 100%;
     height: 100px;
     background-color: #f9f9f9;
     position: fixed;
     bottom: 0;
     right: 0;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     z-index: 11;
     backdrop-filter: blur(5px);
 }

 .rowMenuItem img {
     width: 24px;
     height: 24px;
     padding-right: 5px;
 }

 .rowMenuItem span {
     padding-left: 5px;
     text-align: right;
     width: 100px;
 }

 .linkSocial {
     display: flex;
 }

 .openImages {
     position: fixed;
     top: 23%;
     background: red;
     width: 90%;
     z-index: 99;
     margin: 0 auto;
     left: 0;
     right: 0;
 }

 .popUpImag {
     height: 100%;
     width: 100%;
     position: fixed;
     background: #00000073;
     z-index: 55;
     backdrop-filter: blur(5px);
     position: absolute;

 }

 .menu {
     border-radius: 0 0 0 16px;
     position: fixed;
     top: -50%;
     right: 0;
     height: 160px;
     background-color: #F9F9F9;
     z-index: 9;
     width: 150px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     animation: menu 0.5s;
     animation-fill-mode: forwards;
     animation-direction: alternate !important;
 }

 @keyframes menu {
     from {
         top: -50%;
     }

     to {
         top: 0%;
     }
 }

 .popUpMenu {
     position: fixed;
     top: 0;
     right: 0;
     width: 100%;
     height: 100vh;
     background-color: rgba(8, 8, 8, 0.459);
     z-index: 9;
     backdrop-filter: blur(5px);
 }

 .rowMenuItem {
     color: #151515;
     text-decoration: none;
     font-size: 12px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 40px;
 }

 .detailesProduct {
     width: 85%;
     margin: 0 auto;
     margin-top: 1rem;
     border-radius: 16px;
     background-color: #f9f9f9;
     display: flex;
     position: absolute;
     z-index: 9;
     aspect-ratio: 1;
     top: 10%;
     right: 0;
     left: 0;
     justify-content: center;
     align-items: center;
     height: 75%;
 }

 .titleDetailes {
     text-align: center;
     font-size: 12px !important;
     font-weight: bold;
 }

 .framwork {
     width: 90%;
     /* aspect-ratio: 1; */
     border: 3px solid #BB010B;
     position: relative;
     display: flex;
     justify-content: space-around;
     flex-direction: column;
     align-items: center;
     overflow: clip;
     height: 90%;
 }

 .framwork p {
     font-size: 10px;
     padding: 0 8px;
 }

 .dotLeft {
     position: absolute;
     width: 10px;
     height: 10px;
     border-radius: 10px;
     display: block;
     background-color: #BB010B;
     top: 30%;
     right: -6px;
 }

 .dotRight {
     position: absolute;
     width: 10px;
     height: 10px;
     border-radius: 10px;
     display: block;
     background-color: #BB010B;
     bottom: 30%;
     left: -7px;
 }

 .contentMoreAbout {
     width: 80%;
     margin: 0 auto;
     top: 25%;
     background-color: #f9f9f9;
     aspect-ratio: 1;
     border-radius: 16px;
     padding: 16px;
     z-index: 1;
     right: 0;
     left: 0;
     position: absolute;
 }

 .framBox {
     border: 1px solid #BB010B;
     padding: 16px;
     border-radius: 4px;
     position: relative;
     height: 80%;
 }


 .paragraphDetailes {
     width: 250px;
     height: 200px;
     overflow: scroll;
 }

 .imgHeadBox {
     position: absolute;
     width: 30%;
     background: #fff;
     top: -15px;
     right: 0;
     height: 35px;
     left: 0;
     margin: 0 auto;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .imgHeadBox img {
     width: 19px;
     height: 23px;
 }

 .price {
     width: 100%;
     text-align: center;
     font-weight: bold;
     background: #ccc;
     padding: 0.2rem !important;
     position: absolute;
     bottom: 0;
     font-size: 14px !important;
 }

 .searchBox {
     position: fixed;
     width: 100%;
     right: 0;
     height: 100%;
     top: 0;
 }

 .boxSearching {
     position: relative;
     z-index: 99;
     display: flex;
     justify-content: space-between;
     padding-top: 20px;
     flex-direction: column;
     background-color: #fff;
     height: 100%;
 }

 .close-btn-search {
     position: fixed;
     left: 20px;
     top: 30px;
 }

 .inputSearch {
     z-index: 69;
     width: 50%;
     margin: 0 auto;
     height: 30px;
     border-radius: 5px;
     padding: 0 10px;
     border: 1px solid #ccc;
 }

 .center-center {
     position: fixed;
     top: 50%;
     right: 0;
     left: 0;
     margin: 0 auto;
     text-align: center;
 }

 .boxResualtSearch {
     width: 90%;
     margin: 0 auto;
     margin-top: 20px;
     overflow: scroll;
 }

 .rowResualtSearch {
     border: 1px solid #ccc;
     width: 85%;
     margin: 0 auto;
     border-radius: 8px;
     margin-top: 1rem;
     text-align: center;
     font-size: 10px;
     box-shadow: inset 0 0 6px 2px #ccc;
     height: 75px;
     align-items: center;
     display: flex;
     justify-content: space-between;
     padding: 0 15px;
 }

 .name-product {
     font-weight: bold;
 }

 .image-product {
     width: 50px;
     aspect-ratio: 1;
 }

 @media (min-width:768px) {
    .popUpMenu{
        position: absolute ;
    }
    .groupingProduct{
        width: 100%;
    }
     .shareMedia {
        width: 20%;
     }
        .h-desktop-100{
            height: 100%;
        }
     body {
        width: 25%;
        margin: 0 auto;
        position: relative;
        height: 850px;
     }

     header,
     footer {
        position: absolute;
     }

     .contentAll {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
     }

     .nextPrevItemArrow .nextBtn {
         right: 35%;
     }

     .nextPrevItemArrow .prevBtn {
         left: 35%;
     }

     .openImages {
         width: 80%;
     }



     .popUpImag video {
         width: 360px;
         height: fit-content;

     }

     .menu {
         right: 37%;
     }

     .menu li {
         list-style: none;
     }
 }

 .popUpImag {
     display: flex;
     justify-content: center;
     align-items: center;
 } 

 